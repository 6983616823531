document.addEventListener("DOMContentLoaded", function () {
    // Hide loader after a delay (e.g., 500ms) when the DOM is fully loaded
    setTimeout(function () {
        hideLoader();
    }, 1500);
});

function hideLoader() {
    // Get the loader element
    var loaderContainer = document.querySelector(".loader-container");

    // Apply a class to trigger the fade-out animation
    loaderContainer.classList.add("fadeOut");

    // After the fade-out animation duration, hide the loader
    setTimeout(function () {
        loaderContainer.style.display = "none";
    }, 500); // Adjust the duration to match your CSS transition duration
}

function showLoader() {
    // Get the loader element
    var loaderContainer = document.querySelector(".loader-container");

    // Apply a class to trigger the fade-out animation
    loaderContainer.classList.add("fadeIn");
    loaderContainer.style.display = "";
}

document.addEventListener("DOMContentLoaded", function () {
    const openVideoLinks = document.querySelectorAll('.open-video');
    const modal = document.getElementById('video-modal');
    const vimeoPlayerContainer = document.getElementById('vimeo-player-container');
    let vimeoPlayer;

    function openVideo(vimeoUrl) {
        // Clear the container before loading a new video
        vimeoPlayerContainer.innerHTML = '';

        // Check if there's an existing player
        if (vimeoPlayer) {
            // Use the Vimeo API destroy method to destroy the player
            vimeoPlayer.destroy().then(() => {
                // Create a new player after destroying the existing one
                createNewPlayer(vimeoUrl);
            });
        } else {
            // If no existing player, create a new one
            createNewPlayer(vimeoUrl);
        }

        // Display the modal
        modal.style.display = 'block';
    }

    function createNewPlayer(vimeoUrl) {
        vimeoPlayer = new Vimeo.Player(vimeoPlayerContainer, {
            url: vimeoUrl,
            width: window.innerWidth,
            height: window.innerHeight,
            controls: false, // Customize player options here
            autoplay: true, // Add autoplay option
            title: false, // Hide video title
            byline: false, // Hide user's byline
            portrait: false, // Hide user's portrait
            share: false, // Hide share button
            buttons: false, // Hide like and share buttons
        });
    }

    openVideoLinks.forEach(function (openVideoLink) {
        openVideoLink.addEventListener('click', function (e) {
            e.preventDefault();
            const vimeoUrl = this.getAttribute('data-vimeo-url');
            openVideo(vimeoUrl);
        });
    });

    const closeModalBtn = document.getElementById('close-modal-btn');
    closeModalBtn.addEventListener('click', function () {
        // Check if there's an existing player
        if (vimeoPlayer) {
            // Pause the video before hiding the modal
            vimeoPlayer.pause().then(() => {
                // Destroy the player to release resources
                vimeoPlayer.destroy().then(() => {
                    modal.style.display = 'none';
                });
            });
        } else {
            // If no existing player, simply hide the modal
            modal.style.display = 'none';
        }
    });

    // load more action
    var loadMoreButton = document.getElementById('load-more-posts');
    if (loadMoreButton) {
        loadMoreButton.addEventListener('click', function() {
            showLoader();
            var currentPage = document.getElementById('current-page').value;
            var xhr = new XMLHttpRequest();
            var ajaxUrl = ajax_object.ajax_url;
            xhr.open('POST', ajaxUrl, true);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xhr.onreadystatechange = function() {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    var response = xhr.responseText;
                    var container = document.querySelector('.grid-container');
                    if (response.trim() !== '') {
                        container.insertAdjacentHTML('beforeend', response);
                        document.getElementById('current-page').value = parseInt(currentPage) + 1;
                    } else {
                        // If no more posts, hide the load more button
                        loadMoreButton.style.display = 'none';
                    }
                }
            };
            xhr.send('action=load_more_posts&page=' + currentPage);
            hideLoader();
        });
    }
});



